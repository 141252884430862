import Form from 'client/core/Form';
import { CLASSES } from 'client/utils/globals';
import ajax from 'client/utils/ajax';
import { scrollTo } from 'client/utils/common';

const SELECTORS = {
    contactUsBlock: '.js-contact-us-wrapper',
    errorBlock: '.js-contact-us-error',
    topicInput: '.js-topic-input',
    subtopicInput: '.js-subtopic-input'
};

class ContactUs extends Form {
    init () {
        this.topicInput = this.getNestedComponentById(this.$el.find(SELECTORS.topicInput).data('id'));
        this.subtopicInput = this.getNestedComponentById(this.$el.find(SELECTORS.subtopicInput).data('id'));

        this.allOptions = this.subtopicInput.$el.find('option').map((i, option) => {
            return {
                id: option.id,
                value: option.value,
                text: option.innerText
            };
        });

        const selectedTopicInputValue = this.topicInput.$el.find('option')
                                        .filter((i, option) => option.selected).prop('value');

        // Show only empty option if topic select option is not selected
        this.subtopicInput.updateOptions(this.allOptions.filter((id, option) => {
            if (selectedTopicInputValue) {
                return option.id === selectedTopicInputValue;
            } else {
                return option.id === '';
            }
        }
        ));

        // Show only option and possible variants
        this.topicInput.addListener('changed', (value) => {
            this.subtopicInput.updateOptions(this.allOptions.filter((id, option) =>
                option.id === '' || option.id === value
            ));

        });
    }

    async onSubmit (element, event) {
        event.preventDefault();

        const isValid = await super.onSubmit();

        if (isValid) {
            let data = this.$el.serializeArray();

            ajax.getJson({
                type: 'post',
                url: this.config.actionUrl,
                contentType: 'application/x-www-form-urlencoded',
                data: data
            }).then(response => {
                if (response && response.success) {
                    this.$el.closest(SELECTORS.contactUsBlock).html(response.successContent);
                } else {
                    this.$el.closest(SELECTORS.contactUsBlock).addClass(CLASSES.hide);
                    this.$el.closest(SELECTORS.errorBlock)
                        .removeClass(CLASSES.hide)
                        .html(window.Resources.SERVER_UNAVAILABLE);
                }
                scrollTo();
            });
        }

        return isValid;
    }
}

export default ContactUs;
